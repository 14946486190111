.CodeWorthLogo {
  a img {
    opacity: .5;
  }

  a:hover img {
    opacity: 1;
  }
}

// Common PSPS styles
.placeholder {
  background-color: #BBB;
  color: #FFF;
  border-radius: 5px;
  border: 1px dashed #444;
  box-shadow: 0 2px 4px rgba(0,0,0,.5) inset;
  text-shadow: 0 -1px 0 rgba(255,255,255,.25);
  text-align: center;
  display: block;
  padding: 1em;

}

.rtl {
  direction: rtl;
}

// BS4 fix
.input-group-text {
  height: 100%;
}

// project styles
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,900');

$tsi-bkg-muted-green: #c1ecdf;
$tsi-bkg-muted-gray: #dcdcdc;
$tsi-bkg-green: #1bb17e;
$tsi-bkg-red: #f05e5c;
$tsi-bkg-gray: #a9a9a9;
$tsi-0: #773030;
$tsi-1: #b14848;
$tsi-2: #ed6060;
$tsi-3: #ed6060;
$tsi-4: #f0ba66;
$tsi-5: #f0ba66;
$tsi-6: #1bb27d;
$tsi-7: #1bb27d;
$tsi-8: #497857;
$tsi-9: #243c2c;

$tsi-hand: #6d6e70;
$tsi-text: #878787;

$tsi-light-green: rgb(0,170,125);
$tsi-yellow: rgb(242,189,94);
$tsi-red: rgb(240,94,92);
$tsi-gray: rgb(64,64,64);

body {
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

ul.navbar-nav {
  li {
    a {
      background-color: transparent;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
    }
  }
}

.navbar .nav-item a {
  text-transform: uppercase;
}

.TSInavbar {
  background-color: transparent;
  border-bottom: 2px solid $tsi-gray;
}

.TSIbkg-green {
  background-color: $tsi-bkg-green;
}
.banner-background-green {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#9de2cd+0,00b27d+100 */
  background: rgb(157,226,205); /* Old browsers */
  background: -moz-linear-gradient(45deg, rgba(157,226,205,1) 0%, rgba(0,178,125,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, rgba(157,226,205,1) 0%,rgba(0,178,125,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, rgba(157,226,205,1) 0%,rgba(0,178,125,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9de2cd', endColorstr='#00b27d',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.banner-background-red {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f7b2b3+0,f9bfbe+100 */
  background: rgb(247,178,179); /* Old browsers */
  background: -moz-linear-gradient(45deg, rgba(247,178,179,1) 0%, rgba(249,191,190,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, rgba(247,178,179,1) 0%,rgba(249,191,190,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, rgba(247,178,179,1) 0%,rgba(249,191,190,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7b2b3', endColorstr='#f9bfbe',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.vertical-spacer {
  height: 60px;
}

.vt-30 {
  margin-top: 30px;
}

.vt-60 {
  margin-top: 60px;
}

.vt-128 {
  margin-top: 128px;
}

.vb-60 {
  margin-bottom: 60px;
}

.tsiFormCtrl {
  border-radius: 0;
  border: 1px solid $tsi-bkg-muted-gray;
  margin: .5em;
}

.tsiFormBtnLg {
  margin: .5em;
  border: none;
  padding: 1em;
}

.noData {
  background-color: $tsi-bkg-muted-green;
  color: $tsi-bkg-green;
  text-align: center;
  font-style: italic;
  padding: 4em;
  border-radius: 1em;
}

$sb-front-color: $tsi-gray;
.subscribeBox {
  background-color: $tsi-bkg-muted-gray;
  color: $sb-front-color;
  text-align: center;
  padding: 2em;
  font-weight: 300;

  .cfa {
    font-size: 25pt;
    line-height: 31pt;
    margin: .5em 0;
  }
  .subscribeForm {
    input {
      display: block;
      transition: background-color 0.5s ease-in-out;
      width: 100%;
      font-weight: 300;
      line-height: 18pt;
      color: $sb-front-color;
      background-color: $tsi-bkg-muted-gray;
      border: 1px solid $sb-front-color;
      padding-left: 0.5em;
      margin-bottom: 20px;

      &:focus {
        box-shadow: none;
        background-color: lighten($tsi-bkg-muted-gray, 10%);
      }

      &.armed {
        background-color: white;
      }
    }

    button {
      transition: box-shadow 0.5s ease-in-out;
      display: block;
      font-weight: 300;
      line-height: 18pt;
      width: 100%;
      color: $sb-front-color;
      background-color: $tsi-bkg-green;
      border: 1px solid $sb-front-color;
      &.armed {
        box-shadow: 0 0 10px rgba(255,255,255,.5);
      }
    }

    select {
      display: block;
      width: 100%;
      margin-bottom: 20px;

      border: 1px solid $sb-front-color;
      border-radius: 0;

      background-color: $tsi-bkg-muted-gray;
      color: $sb-front-color;
      padding: 4px;
    }
  }
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 32pt;
  line-height: 38pt;
  color: $tsi-gray;
  text-align: center;
  text-transform: uppercase;
}

h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 18pt;
  line-height: 23pt;
  color: $tsi-gray;
  text-align: left;
  text-transform: uppercase;
}

h3 {
  @extend .vt-60;
  font-family: 'Roboto', sans-serif;
  font-size: 22pt;
  line-height: 24pt;
  font-weight: 300;
  color: $tsi-gray;
  text-align: center;
}

h6 {
  font-family: 'Roboto', sans-serif;
  font-size: 12pt;
  line-height: 15pt;
  font-weight: 300;
  color: $tsi-gray;
  text-align: center;
}

p,ul,ol,li {
  font-family: 'Roboto', sans-serif;
  font-size: 12pt;
  line-height: 18pt;
  font-weight: 300;
  color: $tsi-gray;

  a {
    color: #FFF;
    background-color: $tsi-bkg-red;
    padding: .2em;

    &:hover {
      text-decoration: none;
      background-color: $tsi-bkg-muted-green;
      color: $tsi-bkg-green;
    }
    &:active {
      text-decoration: none;
      background-color: $tsi-bkg-green;
      color: #FFF;
    }
  }
}

footer {
  background-color: $tsi-bkg-muted-gray;
  border-top: 2px solid #00b27d;
  margin-top: auto;
  padding: 40px 0;

  color: $tsi-bkg-green;

  a {
    color: $tsi-bkg-green;
    &:hover {
      color: #FFF;
      text-decoration: none;
    }
  }
}

table.indexTable {
  font-size: 0.8em;
  td {
    vertical-align: middle;
  }
}

table.indexTableFront {
  td {
    border: 1px solid white
  }

  td.bold {
    font-weight: bold;
  }

  th {
    border: none;
  }
  .political{
    background-color: #E7F2E6;
  }

  .diplomacy{
    background-color: #D2E8D1;
  }

  .reality{
    background-color: #E7F2E6;
  }

  .solvability{
    background-color: #D2E8D1;
  }
}

.bannerCtnr {
  background-size: 100%;
  background-repeat: repeat-y;
  background-attachment: fixed;
  background-image: url(/assets/images/TSI-Banner-bkg.png);
  a {
    display: block;

    img {
      margin: 1em 0;
      display: block;
      background-color: rgba(255,255,255,1);
      padding: 10px;
      //width:100%;
      //height: auto;
    }
  }
}

// bootstrap md and up
@media (max-width: 800px) {
  .bannerCtnr {
    a {
      img {
        width: 600px;
      }
    }
  }
}

@media (max-width: 620px) {
  .bannerCtnr {
    a {
      img {
        width: 320px;
      }
    }
  }
}


.overallIndex {

  border: 1px solid $tsi-bkg-muted-gray;
  display: inline-block;

  div {
    display: inline-block;
    padding: .3em 1em;
  }
  .title {
    font-weight: bold;
  }

  .value {
    background-color: $tsi-bkg-muted-gray;
  }
}


.btn-text {
  background-color: transparent;
  &:hover {
    color: $tsi-bkg-green;
  }
  &:active{
    color: $tsi-red;
  }
}

/*
 * Button container and button container container. Used for adding buttons to title blocks.
 */
.btn-cc {
  position: relative;

  .btn-c {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: auto;

  }
}

.loadingInProgress {
  opacity: .5;
  background-color: #CCC;
}

.loginForm {
  margin-top: 15vh;
  border: 1px solid #000;
  box-shadow: 0 4px 60px rgba(0,0,0,.5);
}

.newsletterCtnr {
  iframe {
    display: block;
    width: 100%;
    height: 80em;
  }

  .aCtnr {
    display: block;
    width: 100%;
    padding: 2em 0;
    text-align: center;
    font-size: larger;
  }

}